import React from "react";
import Layout from "../_components/Layout/Layout";
import Banner from "../_components/Banner/Banner";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";
import { giftsList, rewardsList } from "../_components/constants";
import StyledLink from "../_components/StyledLink/StyledLink";

const RewardLander = () => {
    return (
        <Layout title="Welcome" hideSubNav={true}>
            <div className="title-wrapper">
                <MainTitle>Unlock your gift from us</MainTitle>

                <PageSummary>
                    Here are some of the gifts you might unlock after registering your details.
                </PageSummary>
            </div>

            <ClaimRewardBtn />

            {giftsList?.map((item, index) => (
                <Banner
                    key={index}
                    image={item.image}
                    text={item.text}
                />
            ))}

            <div className="rewards-carousel">
                <MainTitle className={'gifts'}>Other Gifts</MainTitle>

                <RewardsCarousel rewardsList={rewardsList} />
            </div>

            <StyledLink rewardsButton to="/home">
                Explore Glide
            </StyledLink>
        </Layout>
    );
};

export default RewardLander;
